<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            :editable="editable"
            label="공정"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            label="작업기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작성상태 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="tbmCompleteFlag"
            label="작성상태"
            v-model="searchParam.tbmCompleteFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        작업허가서
        <div class="card-more">
          <q-btn-group outline >
            <c-btn label="화기 등록" icon="add" @btnClicked="addFire" />
            <c-btn label="일반 등록" icon="add" @btnClicked="addNormal" />
          </q-btn-group>
        </div>
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.tbmCompleteFlag)]">
                    {{ props.row.tbmCompleteFlagName }}
                  </div>
                  <div :class="['text-grid-etc', 'txt-box-grid text-grey-box']">
                    {{ props.row.tbmNo }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.tbmName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.tbmWorkDate }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionRelactionTaskTbm',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'tbmWorkDate',
            field: 'tbmWorkDate',
            label: '작업일',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmCompleteFlagName',
            field: 'tbmCompleteFlagName',
            label: '작성상태',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        startYmd: '',
        endYmd: '',
        period: [],
        plantCd: '',
        processCd: '',
        tbmCompleteFlag: null,
        sopConstructionId: '',
        constructionFlag: true,
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '작성완료' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.tbm.list.url;
      // code setting
      this.$set(this.searchParam, 'sopConstructionId', this.popupParam.sopConstructionId)
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'N': // 작성중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'Y': // 작성완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
    // linkClick(row, col) {
    //   this.detailPopup(row, col);
    // },
    // detailPopup(result, col) {
    //   if (col && col.name === 'permitNo') {
    //     this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
    //     this.popupOptions.title = '작업허가서 상세';
    //     this.popupOptions.param = {
    //       sopWorkPermitId: result ? result.sopWorkPermitId : '',
    //       permitTypeCd: result ? result.permitTypeCd : '',
    //     };
    //   } else {
    //     this.popupOptions.target = () => import(`${"@/pages/sai/tbm/tbmDetail.vue"}`);
    //     this.popupOptions.title = 'TBM 상세';
    //     this.popupOptions.param = {
    //       tbmId: result ? result.tbmId : '',
    //     };
    //   }
    //   this.popupOptions.isFull = true;
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup() {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   this.getList();
    // },
    // deleteTask() {
    //   let selectData = this.$refs['tasktable'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내', // 안내
    //       message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //       title: '확인',
    //       message: '제외하시겠습니까?',
    //       // TODO : 필요시 추가하세요.
    //       type: 'info', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = this.task.deleteUrl;
    //         this.$http.type = 'DELETE';
    //         this.$http.param = {
    //           data: Object.values(this.$_.map(selectData, (item) => {
    //             return {
    //               sopConstructionId: this.popupParam.sopConstructionId,
    //               tbmId: item.tbmId,
    //             }
    //           }))
    //         };
    //         this.$http.request(() => {
    //           window.getApp.$emit('APP_REQUEST_SUCCESS');
    //           this.$refs['tasktable'].$refs['compo-table'].clearSelection();
    //           this.getList();
    //         },);
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
    // addTask() {
    //   this.popupOptions.title = 'TBM 검색'; // TBM 검색
    //   this.popupOptions.param = {
    //     type: 'multiple'
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/sai/tbm/tbmPop.vue'}`);
    //   this.popupOptions.width = '80%';
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeTaskPopup;
    // },
    // closeTaskPopup(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     let insertList = [];
    //     this.$_.forEach(data, item => {
    //       if (this.$_.findIndex(this.grid.data, { tbmId: item.tbmId }) === -1) {
    //         insertList.push({
    //           sopConstructionId: this.popupParam.sopConstructionId,
    //           tbmId: item.tbmId,
    //         });
    //       }
    //     })

    //     this.$http.url = this.task.insertUrl;
    //     this.$http.type = 'POST';
    //     this.$http.param = insertList
    //     this.$http.request(() => {
    //       window.getApp.$emit('APP_REQUEST_SUCCESS');
    //       this.getList();
    //     },);
    //   }
    // },
  }
};
</script>
